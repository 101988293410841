<template lang="pug">
v-container(fill-height)
  v-row(justify="center")
    v-col(md="6")
      v-card-title
        .text-h4 {{ $t('auth.logout.title') }}
      v-card-text
        .text-body {{ $t('auth.logout.text') }}
      v-card-actions
        v-btn(@click="goLogin", block, color="primary") 
          v-icon.mr-4 mdi-login
          | {{ $t('auth.btn.login') }}
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("auth/userLogout");
  },
  methods: {
    goLogin() {
      this.$router.push({ name: "authLogin" });
    },
  },
};
</script>